import { Injectable } from '@angular/core';
// import { TmmserviceService } from './tmmservice.service';
import { sha256 } from 'js-sha256';
import { Tmmstorage } from '../_shared/tmmstorage';
import { BehaviorSubject, Subject } from 'rxjs';

export interface User{
    username : string,
    smashAccountNo : string,
    firstName : string,
    lastName : string,
    // mobileNumber : string,
    email : string,
    shortCode : string,
    gender : string
    countryCode : string,
    dateOfBirth : string,
    receiveNewsletter : boolean,
}
export type authDetails={
  secret:string,
  // sid:string,
  // generatedPass:string,
  orgId:string,
  firebaseToken?:string
}
export type refreshState={
  smashAccountNo : string,
  refreshStores: boolean,
  refreshFlyers: boolean
};
@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {
  TmmstorageClassInstance = new Tmmstorage();

  private _isLoggedIn:boolean=false;
  private _userDetails: User;
  private _authDetails: authDetails;
  private _refreshState: refreshState; 
  public authDetailsObservable: BehaviorSubject<string | boolean> = new BehaviorSubject(false);



  constructor(   
    // public apiService: TmmserviceService
  ) {
    //console.log("auth constructor");
  }

  public set isLoggedIn(status:boolean){
    this._isLoggedIn= status;
  } 
  public set userDetails(userDetails: User){
    this._userDetails= userDetails;
  }
  public get userDetails(){
    return this._userDetails;
  }
  public get isLoggedIn(){
    return this._isLoggedIn;
  }
  public set authDetails(authDetails:authDetails){
    this._authDetails=authDetails;
  }
  public get authDetails(){
    return this._authDetails;
  }
  public set refreshState(refreshState: refreshState){
    this._refreshState= refreshState;
  }
  public get refreshState(){
    return this._refreshState;
  }
  public async setInStorage(objectName:string, value: User|authDetails|refreshState){
    //console.log("setInstorage");
    await this.TmmstorageClassInstance.setObject(objectName, value).then(()=>{
      if(objectName=='authDetails'){
        //console.log("object name trigger observable");
        this.authDetailsObservable.next(true);
      }
      this.TmmstorageClassInstance.getObject(objectName).then(Res=>{
        //console.log("Res objectName" +objectName);
        //console.log(Res); 
      });
    });
  }

  // public async login(username, password ): Promise<any>{
  //   await this.apiService.LOGIN(username, password).then(async result => {
  //     if (result.status == 0) {
  //       let data = JSON.parse(result.data.replace(/\r?\n|\r/g, ''));
  //       let headers = result.headers;
  //       var tempAuthDets:authDetails;
  //       tempAuthDets.secret=data.responseBody.secret;
  //       tempAuthDets.sid=headers.sid;
  //       // tempAuthDets.generatedPass= sha256(tempAuthDets.secret.concat(tempAuthDets.sid));
  //       this.authDetails=tempAuthDets;
  //     }
  //     return result;
  //   });
  // }


  public logout(){ 
    this.TmmstorageClassInstance.removeItem("authDetails");
    this.TmmstorageClassInstance.removeItem("userDetails");
    this.TmmstorageClassInstance.removeItem("refreshState");
    this.userDetails=undefined;
    //console.log("userdetails?", this.userDetails);
    this.authDetails=undefined;
    this.refreshState=undefined;
    this.isLoggedIn=false;
  }

  // :Promise<boolean>
  public async getCachedData(){
     await this.TmmstorageClassInstance.getObject("userDetails").then(async userDetails => {
        this.userDetails= userDetails;
       await this.TmmstorageClassInstance.getObject("authDetails").then(authDetails => {
        //console.log("authDetails storage", authDetails);
        this.authDetails= authDetails;
        if(this.userDetails!=undefined && this.authDetails!=undefined){
          this.isLoggedIn=true;
          this.authDetailsObservable.next(true);
        }else{
          this.isLoggedIn=false;
        }
      });
    });
     
    
     this.TmmstorageClassInstance.getObject("refreshState").then(refreshState => {
      //console.log("refreshState storage", refreshState);
      this.refreshState= refreshState;
      //console.log("this.refreshState", this.refreshState);
    });

   
    
  }

  public async migrateData(): Promise<string>{
    await this.TmmstorageClassInstance.getObject("profile").then(async profile => {   
      //console.log("migrate?");
      if(profile != null){
        //console.log("profile", profile);
        this.authDetails={
          // generatedPass:profile.generated_pass,
          orgId:profile.ORGID,
          secret:  profile.secret,
        };
      
        //console.log("authDetails?",this.authDetails);
        this.userDetails={
          username: profile.username,
          shortCode: profile.short_code,
          gender: profile.gender,
          dateOfBirth: profile.date_of_birth,
          countryCode: profile.country_code,
          smashAccountNo: profile.account_number,
          receiveNewsletter: profile.receive_newsletter,
          firstName: profile.first_name,
          lastName: profile.last_name,
          email: profile.email         
        };
        await this.TmmstorageClassInstance.setObject("userDetails",  this.userDetails);

        await this.TmmstorageClassInstance.setObject("authDetails",  this.authDetails);
        this.refreshState={
          smashAccountNo:profile.account_number,
          refreshFlyers: profile.refreshFlyers,
          refreshStores: profile.refreshStores
        };
        await this.TmmstorageClassInstance.setObject("refreshState",  this.refreshState);
        //console.log("authDetails", this.authDetails);
        this.isLoggedIn=profile.is_user_loggedin;
        this.TmmstorageClassInstance.removeItem("profile");
      }
    });
    return "done";
  }
  
}
