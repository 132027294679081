import { Injectable } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
 import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  analyticsEnabled = true;
 
  constructor( private router: Router) {
    // this.enableCollection();
    this.router.events.pipe(
      filter((e: RouterEvent) => e instanceof NavigationEnd),
    ).subscribe((e: RouterEvent) => {
      var page= e.url;
      //console.log("page?? ", page);
      
      if(page!="/"){
        var test = page.split(/\/tabs\//);
        var resultpagename
        
        if(test[1]==undefined){
          resultpagename = test[0].split(/\//);
          resultpagename=resultpagename[1];
          // //console.log(resultpagename);
        }
  
        else {
          resultpagename = test[1].split(/\?.*/);
          resultpagename= resultpagename[0];
        }
        // //console.log("the results ", resultpagename);
        // this.setScreenName(resultpagename);
       //   this.logEvent(resultpagename);

    }

    });
  
  }
 
  setScreenName(name) {
    //console.log("setScreenName?? ", name)
    FirebaseAnalytics.setScreenName({
      screenName : name, 
      nameOverride: "CAPBridgeViewController"
    });
  }
  enableCollection(){
    FirebaseAnalytics.setCollectionEnabled({
      enabled: true,
    });
  }
}