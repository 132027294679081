import { Injectable } from "@angular/core";
import { Tmmstorage } from "../_shared/tmmstorage";
import { Languageswitcher } from "../_shared/languageswitcher";
import { Capacitor } from "@capacitor/core";
import { Kid } from "./generalmethods.service";
import { CapacitorHttp } from "@capacitor/core";
import { AuthServiceService } from './auth-service.service';

@Injectable({
  providedIn: "root",
})
export class TmmservicebrowserService {
  TmmstorageClassInstance = new Tmmstorage();
  StoresJsonObj: any = {};
  RaincheckJsonObj: any = {};
  JsonObj: any = {};
  ProfileJsonObj: any = {};
  organization_code: any;
  AppJsonObj: any;
  Lang = "";
  version;
  LanguageswitcherClassInstance = new Languageswitcher();
  customheaders: any = {};
  url: any;
  sid;
  PHILOClientId = "tamimiRewardsApp";
  runningplatform;

  constructor(public authService: AuthServiceService) {
    //console.log("tmmservice browser constructor");
    // this.getOrgId();
    this.runningplatform = Capacitor.getPlatform();
    if (this.runningplatform == "web") {
      this.url = window.location.origin + "/proxy/angular/external/v2/";
    }
    this.authService.authDetailsObservable.subscribe(authDetsInitialization=>{
      this.setHeaders();
    });
    
  }
  setHeaders(){
    this.customheaders = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "PHILO-OrgId": '628001',
      // this.authService.authDetails.orgId,
      "PHILO-ClientId": this.PHILOClientId,
    };
  }

  async getOrgId() {
    this.organization_code = "628001";
  }

  async GET_GIFTCARD_BALANCE(reference) {
    return await CapacitorHttp.get({
      url: `${this.url}smashAccount`,
      params: {
        reference: reference,
        smashAccountType: "GIFTCARD",
      },
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  }

  // smashAccountType=${accounttype}
  async GET_SMASH_ACCOUNT(accounttype) {
    return await CapacitorHttp.get({
      url: `${this.url}smashAccount`,
      params: {},
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  }

  // POST: VERIFY_USER
  async VERIFY_USER(mobile_no: string, OTP_code: string) {
    return await CapacitorHttp.post({
      url: `${this.url}smashMember/isValidated`,
      data: {
        username: mobile_no,
        code: OTP_code,
      },
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  } // POST: VERIFY_USER

  // POST: RESET_PASSWORD
  async RESET_PASSWORD(mobile_no: string) {
    return await CapacitorHttp.post({
      url: `${this.url}smashMember/password?action=reset`,
      data: {
        username: mobile_no,
      },
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  } // POST: RESET_PASSWORD

  async CHECK_LOYALTY_NUMBER(short_code: string) {
    return await CapacitorHttp.get({
      url: `${this.url}smashAccount`,
      params: {
        shortCode: short_code,
      },
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  } // POST: RESET_PASSWORD

  // POST: REGISTER
  async REGISTER(
    username: string,
    password: string,
    first_name: string,
    last_name: string
  ) {
    return await CapacitorHttp.put({
      url: `${this.url}smashMember?action=register`,
      data: {
        username: username,
        password: password,
        firstName: first_name,
        lastName: last_name,
      },
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  } // PUT: REGISTER

  // POST: REGISTER_LOYALTY
  async REGISTER_LOYALTY(
    first_name: string,
    last_name: string,
    gender: string,
    nationality: string,
    newsletter: string,
    email: string,
    // favorite_store: string,
    date_of_birth: string,
    shortcode: string
  ) {
    var register_loyalty_params: any = {};
    register_loyalty_params = {
      countryCode: nationality,
      dateOfBirth: date_of_birth,
      // "favoriteStore": favorite_store,
      receiveNewsletter: "true",
      email: email,
    };
    if (gender != undefined) {
      register_loyalty_params.gender = gender;
    }
    if (shortcode != null) {
      register_loyalty_params.shortCode = shortcode;
    }

    return await CapacitorHttp.put({
      url: `${this.url}smashMember?action=registerLoyalty`,
      data: register_loyalty_params,
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  } // POST: REGISTER_LOYALTY

  async GET_TRANSACTIONS(
    accounttype,
    items_no: string,
    cursor: string,
    username
  ) {
    return await CapacitorHttp.get({
      url: `${this.url}smashTransaction`,
      params: {
        username: username,
        size: String(items_no),
        cursor: cursor,
        smashAccountType: accounttype,
      },
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  } // GET: GET_TRANSACTIONS
  async GET_FLYERS(lon, lat) {
    if (lon && lat) {
      var lonsliced = Number(lon).toFixed(6);
      var latsliced = Number(lat).toFixed(6);
      this.StoresJsonObj.longitude = String(lonsliced);
      this.StoresJsonObj.latitude = String(latsliced);
    }
    let flyersJsonObj: any = {};
    return await CapacitorHttp.get({
      url: `${this.url}promoFlyer`,
      params: {},
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  } // GET: FLYERS

  // GET: LOGOUT
  async LOGOUT(firebasetoken) {
    // "registrationtokenid" : firebasetoken
    return await CapacitorHttp.get({
      url: `${this.url}smashMember/logout`,

      params: {},
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  } // GET: LOGOUT

  //V2
  async LOGIN(username: string, password: string, firebasetoken: string) {
    return await CapacitorHttp.post({
      url: `${this.url}smashMember/login`,
      data: {
        username: username,
        password: password,
        registrationTokenId: firebasetoken,
      },
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  } // POST: LOGIN

  //V2
  async GET_SMASH_MEMBER_PUBLIC(username) {
    return await CapacitorHttp.get({
      url: `${this.url}smashMember?username=${username}`,
      params: {},
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  } // GET: GET_SMASH_MEMBER

  //V2 Send verification code - Verify Step 1
  async SEND_VERIFICATION_CODE(mobileWithCountryCode) {
    return await CapacitorHttp.post({
      url: `${this.url}smashMember/isValidated`,
      data: {
        username: mobileWithCountryCode,
      },
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  } // GET: GET_SMASH_MEMBER

  async VALIDATE_SESSION(username, generatedPass) {
    return await CapacitorHttp.post({
      url: `${this.url}smashMember/validateSession`,
      data: {
        username: username,
        generatedPass: generatedPass,
      },
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1;
        } else {
          error.status = 1;
        }
        return error;
      });
  }

  async UPDATE_PROFILE(field_key: string, field_value_to_update: string) {
    let ProfileJsonObj={};
    ProfileJsonObj[field_key] = field_value_to_update;
    //console.log("update prof");
    //console.log(ProfileJsonObj);

    return await CapacitorHttp.patch({
      url: `${this.url}smashMember`,
      data: ProfileJsonObj,
      // {
      // "gender": "F",
      // },
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        //console.log("data status?", data.status);
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1;
        } else {
          error.status = 1;
        }
        return error;
      });
  } // POST: UPDATE_PROFILE

  // POST: CHANGE_PASSWORD
  async CHANGE_PASSWORD(username: string, OTP, password: string) {
    return await CapacitorHttp.post({
      url: `${this.url}smashMember/password`,

      data: {
        code: OTP,
        username: username,
        password: password,
      },
      headers: this.customheaders,
    })
      .then((data) => {
        data.status = 0;
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1;
        } else {
          error.status = 1;
        }
        return error;
      });
  } // POST: CHANGE_PASSWORD

  async UPDATE_PASSWORD(password: string, previous_password: string) {
    return await CapacitorHttp.put({
      url: `${this.url}smashMember/password`,

      data: {
        password: password,
        previousPassword: previous_password,
      },
      headers: this.customheaders,
    })
      .then((data) => {
        data.status = 0;
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1;
        } else {
          error.status = 1;
        }
        return error;
      });
  } // POST: UPDATE_PASSWORD

  async GET_SMASH_MEMBER() {
    return await CapacitorHttp.get({
      url: `${this.url}smashMember`,
      params: {},
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  } // GET: GET_SMASH_MEMBER

  //GET_SOCIAL_PROMOS
  async GET_SOCIAL_PROMOS() {
    return await CapacitorHttp.get({
      url: `${this.url}promo`,

      params: {},
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  }

  async GET_REDEMPTION_TIERS() {
    return await CapacitorHttp.get({
      url: `${this.url}redemptionTier`,

      params: {},
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  }

  async PREPARE_ORDER(TYPE, AMOUNT, CURRENCY, ACCOUNT_TO) {
    return await CapacitorHttp.post({
      url: `${this.url}smashOrder`,
      data: {
        orderType: TYPE,
        amount: AMOUNT,
        currency: CURRENCY,
        accountTo: ACCOUNT_TO,
      },
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  }

  async SUBMIT_ORDER(ORDER_ID, TYPE, AMOUNT, CURRENCY, ACCOUNT_TO) {
    return await CapacitorHttp.put({
      url: `${this.url}smashOrder/${ORDER_ID}`,
      data: {
        id: ORDER_ID,
        orderType: TYPE,
        amount: AMOUNT,
        currency: CURRENCY,
        accountTo: ACCOUNT_TO,
      },
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  }

  async GET_SP_PORTAL_LINK() {
    return await CapacitorHttp.get({
      url: `${this.url}socialPromo/portalLink`,

      params: {},
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  }

  async GET_CHARITY_ORGANIZATIONS() {
    return await CapacitorHttp.get({
      url: `${this.url}charityOrganization`,
      params: {},
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  }
  async ADD_MISSED_POINTS(TID) {
    return await CapacitorHttp.post({
      url: `${this.url}smashAccount?action=addMissedPoints`,

      data: {
        posTxnId: TID,
      },
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  }

  async ADD_RAINCHECK_PRODUCT(BARCODE, lon, lat) {
    var lonsliced = Number(lon).toFixed(6);
    var latsliced = Number(lat).toFixed(6);
    var longitude = String(lonsliced);
    var latitude = String(latsliced);

    return await CapacitorHttp.get({
      url: `${this.url}rainCheckProduct/${BARCODE}?geoLatitude=${latitude}&geoLongitude=${longitude}`,
      params: {},
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  }

  async GET_NOTIFICATIONS(
    messageType: string,
    isRead,
    cursor: string,
    sizeNumber: number,
    isCounterOnly
  ) {
    var size = sizeNumber.toString();

    return await CapacitorHttp.get({
      url: `${this.url}smashMemberNotification`,
      params: {
        messageType: messageType,
        isRead: isRead,
        cursor: cursor,
        pageSize: size,
        isCounterOnly: isCounterOnly,
      },
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  }

  async UPDATE_NOTIFICATION(id: string, isRead: boolean) {
    return await CapacitorHttp.patch({
      url: `${this.url}smashMemberNotification/${id}`,
      data: {
        isRead: isRead,
      },
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  }
  async GET_KIDS() {
    return await CapacitorHttp.get({
      url: `${this.url}smashMemberDependant`,
      params: {},
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  }
  async CREATE_KID(kidDetails: Kid) {
    return await CapacitorHttp.post({
      url: `${this.url}smashMemberDependant`,
      data: {
        name: kidDetails.name,
        gender: kidDetails.gender,
        dateOfBirth: kidDetails.dateOfBirth,
      },
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200 || data.status == 201) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  }
  async DELETE_KID(id: string) {
    return await CapacitorHttp.delete({
      url: `${this.url}smashMemberDependant/` + id,
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  }
  async UPDATE_KID(kid: Kid) {
    return await CapacitorHttp.patch({
      url: `${this.url}smashMemberDependant/` + kid.id,
      data: {
        name: kid.name,
        gender: kid.gender,
        dateOfBirth: kid.dateOfBirth,
      },
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        } else {
          error.status = 1; //error
        }
        return error;
      });
  }
  async DELETE_ACCOUNT() {
    return await CapacitorHttp.delete({
      url: `${this.url}smashMember`,
      headers: this.customheaders,
    })
      .then((data) => {
        if (data.status == 200) {
          data.status = 0; //success
        } else if (data.status == 401) {
          data.status = -1; //validate session
        } else {
          data.status = 1; //error
        }
        return data;
      })
      .catch((error) => {
        if (error.status == 401) {
          error.status = -1; //validate session
        }
        return error;
      });
  }
}
