// import { Ionconfig, IonconfigAR } from '../ionconfig.enum';

export class Languageswitcher {
    IONCONFIG_VALUE;
    constructor() { }

    getLang() {
        // let LANG = String(navigator.language.split("-",1));
        // switch(LANG){
        //     case "ar":{
        //       // alert("ar");
        //       this.IONCONFIG_VALUE = IonconfigAR;
        //       break;
        //     }
        //     // case "el":{
        //     //   this.IONCONFIG_VALUE = IonconfigAR;
        //     //   break;
        //     // }
        //     default: {
        //       // alert("default");
        //       this.IONCONFIG_VALUE = Ionconfig;
        //       break;
        //     }
        //   }
        // return this.IONCONFIG_VALUE;
    }
}
