import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import {GeneralmethodsService} from '../_services/generalmethods.service'; 

export enum ConnectionStatus {
  Online,
  Offline
}

@Injectable({
  providedIn: 'root'
})

export class HandleNetworkService { 
  public status: ConnectionStatus;
  private _status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(null);

  constructor(
    public generalmethods: GeneralmethodsService,
      public network: Network,
      public toastController: ToastController
  ) {
      this.status = ConnectionStatus.Online;
  }

  //TOAST FOR NO INTERNET
  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 10000,
      position: 'middle',
      buttons: [{
          text: 'X',
          role: 'cancel'
        }
      ]
    });
    toast.present();
  }

  public initializeNetworkEvents(): void {
      /* OFFLINE */
      this.network.onDisconnect().subscribe(() => {
          if (this.status === ConnectionStatus.Online) {
              this.setStatus(ConnectionStatus.Offline);
              this.presentToast(this.generalmethods.gettranslation('DINO_AGE'));
          }
      })

      /* ONLINE */
      this.network.onConnect().subscribe(() => {
          if (this.status === ConnectionStatus.Offline) {
              this.setStatus(ConnectionStatus.Online);
          }
      })

  }

  public getNetworkType(): string {
    /*TODO: disable network related checks on the web*/
      return this.network.type
  }

  public getNetworkStatus(): Observable<ConnectionStatus> {
      return this._status.asObservable();
  }
  
  private setStatus(status: ConnectionStatus) {
      this.status = status;
      this._status.next(this.status);
  }
  
}
