import { Component} from '@angular/core';
import { Platform, AlertController, LoadingController, ModalController } from '@ionic/angular';
import { Tmmstorage } from './_shared/tmmstorage';
import { MenuController } from '@ionic/angular';
import { Languageswitcher } from './_shared/languageswitcher';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import {HandleNetworkService} from './_services/handle-network.service';
import { enableProdMode } from '@angular/core';
import { AnalyticsService } from './_services/analytics.service';
import {GeneralmethodsService} from './_services/generalmethods.service'; 
import { Constants } from 'src/app/_shared/constants.enum';
import { CookieService } from 'ngx-cookie-service';
import { AuthServiceService } from "./_services/auth-service.service";

enableProdMode();


@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {  
  TmmstorageClassInstance = new Tmmstorage();
  LanguageswitcherClassInstance = new Languageswitcher();
  enabled = this.analyticsService.analyticsEnabled;
  connectedinnetwork;
  nettype;

  constructor(
    private cookieService: CookieService,
    public generalmethods: GeneralmethodsService,
    private screenOrientation: ScreenOrientation,
    private menu: MenuController,
    public modalController: ModalController,
    public loadingController: LoadingController,
    public alertController: AlertController,
    private platform: Platform,
    private authService: AuthServiceService,
    public networkstatus : HandleNetworkService,
    private analyticsService: AnalyticsService) {
      this.authService.migrateData().then(async migrationDone=>{
        //console.log("migration done?", migrationDone);
      });
      if(this.generalmethods.runningplatform== 'web'){
        var languagecookie =this.cookieService.get('_icl_current_language');
        //console.log("languagecookie", languagecookie);
        if(languagecookie==""){
          this.cookieService.set('_icl_current_language', 'en');
          languagecookie= 'en';
        }
        this.generalmethods.setAppLanguage(languagecookie);
        this.networkstatus.initializeNetworkEvents();
        this.networkstatus.getNetworkType();
        this.getwidth();
      }else{
        this.TmmstorageClassInstance.getStorageValue("applanguage").then(async languageval=>{
          if(languageval==undefined || languageval==null){
            languageval="default";
          }
          this.generalmethods.setAppLanguage(languageval);
          this.networkstatus.initializeNetworkEvents();
          this.networkstatus.getNetworkType();
          this.initializeApp();
      });
      }
      //subsciber after reconnecting to the internet 
      this.networkstatus.getNetworkStatus().subscribe((data)=>{
        if(data==0 && this.connectedinnetwork==false ){
          this.connectedinnetwork=true;
        }
      });   
  }
  openFirst() {
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }  

  setPortrait(){
    // set to portrait
    // this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
  }

  async initializeApp() {
    this.nettype= this.networkstatus.getNetworkType();
    await this.platform.ready().then(async () => {
      // this.setPortrait();
      this.generalmethods.width= this.platform.width();
    });
  }
 
  async getwidth(){
    await this.platform.ready().then(async () => {
      this.generalmethods.width= this.platform.width();
    });
  }
}
