import { Component, OnInit } from '@angular/core';
import {GeneralmethodsService} from '../_services/generalmethods.service'; 
import { Constants } from '../_shared/constants.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-webheader',
  templateUrl: './webheader.component.html',
  styleUrls: ['./webheader.component.scss'],
})
export class WebheaderComponent implements OnInit {
  CONSTANTS=Constants;
 
  display=false;
  language;
  countryselection=[
    {
      country_code : "gb",
      shownname: "En"
    },{
      country_code : "sa",
      shownname: "Ar"
    }
  ];

  constructor(public generalmethods: GeneralmethodsService, public document: Document, public router: Router) { }

  ngOnInit() {
    this.language= this.generalmethods.currentlanguage();
    if(this.language == this.countryselection[0].shownname.toLowerCase()){
      this.generalmethods.currentselectedcountry=this.countryselection[0];
      this.generalmethods.toselectcountry=this.countryselection[1];
    }else {
      this.generalmethods.currentselectedcountry=this.countryselection[1];
      this.generalmethods.toselectcountry=this.countryselection[0];
    }
  }

 
  changelanguage(){
    this.generalmethods.setAppLanguage(this.generalmethods.toselectcountry.shownname.toLowerCase());
     var exchangelang= this.generalmethods.currentselectedcountry;
    this.generalmethods.currentselectedcountry= this.generalmethods.toselectcountry;
    this.generalmethods.toselectcountry=exchangelang;
    //console.log("router?", this.router.url);
    var routes=this.router.url.split("/");
    //console.log("routes[routes.length-1]", routes[routes.length-1]);
    if(routes[routes.length-1]=="login"){
      location.reload();
    }
  }
}
