import { Preferences } from '@capacitor/preferences';


export class Tmmstorage {
    CardsCounter;
    parentJsonHash: any;
    jsonObj: any;
    jsonObjRetrieveMultiple: any;
    public increaseCardCounterValue: number;

    async setStorageValue(itemKey: any, itemValue: any) {
        await Preferences.set({
            key: itemKey,
            value: itemValue
        });
    }

    async getStorageValue(itemKey: any) {
        const { value } = await Preferences.get({ key: itemKey });
        return value;
    }

    async setObject(parentJsonHash: any, jsonObj: any) {
        //    //console.log("GOt jsonObj"+jsonObj);
        await Preferences.set({
            key: parentJsonHash,
            value: JSON.stringify(jsonObj)
        });
    }

    async getObject(parentJsonHash: any) {
        const ret = await Preferences.get({ key: parentJsonHash });
        const card = JSON.parse(ret.value);
        return card;
    }

 

    async setRegister(jsonObj: any) {
        this.setObject("register", jsonObj);
    }

    async setNewCard(jsonObj: any) {
        this.CardsCounter = this.getStorageValue("CardsCounter");
        this.CardsCounter.then(data => {
            if (data) {
                this.increaseCardCounterValue = Number(data) + 1;
                this.setStorageValue("CardsCounter", String(this.increaseCardCounterValue));
                this.parentJsonHash = this.increaseCardCounterValue;
                this.setObject(String(this.parentJsonHash), jsonObj);
                this.setStorageValue(jsonObj.shortCode, String(this.parentJsonHash));
                this.setStorageValue("thisShortCode", jsonObj.shortCode);
                this.setStorageValue("thisCardCounter", String(this.parentJsonHash));
            } else {
                this.setStorageValue("CardsCounter", "0");
                this.setObject("0", jsonObj);
                this.setStorageValue(jsonObj.shortCode, "0");
                this.setStorageValue("thisShortCode", jsonObj.shortCode);
                this.setStorageValue("thisCardCounter", "0");
            }
        });
    }

    async setNewRetrievedCards(retrievedGiftcardsObj: any) {
        for (let giftcard in retrievedGiftcardsObj.response_body.linked_giftcards) {
            this.jsonObjRetrieveMultiple =  {
                shortCode: retrievedGiftcardsObj.response_body.linked_giftcards[giftcard].short_code,
                cardName: retrievedGiftcardsObj.response_body.mobile_number,
                currency: retrievedGiftcardsObj.response_body.linked_giftcards[giftcard].currency,
                balance: retrievedGiftcardsObj.response_body.linked_giftcards[giftcard].balance,
                expires: retrievedGiftcardsObj.response_body.linked_giftcards[giftcard].expires,
                accountNo: retrievedGiftcardsObj.response_body.linked_giftcards[giftcard].account_no,
                mobileNumber: retrievedGiftcardsObj.response_body.mobile_number,
                OTP: "n/a",
                status: 3,
            };
            this.CardsCounter = this.getStorageValue("CardsCounter");
            await this.CardsCounter.then(data => {
                if (data) {
                    this.increaseCardCounterValue = Number(data) + 1;
                    this.setStorageValue("CardsCounter", String(this.increaseCardCounterValue));
                    this.parentJsonHash = this.increaseCardCounterValue;
                    this.setObject(String(this.parentJsonHash), this.jsonObjRetrieveMultiple);
                    this.setStorageValue(this.jsonObjRetrieveMultiple.shortCode, String(this.parentJsonHash));
                    this.setStorageValue("thisShortCode", this.jsonObjRetrieveMultiple.shortCode);
                    this.setStorageValue("thisCardCounter", String(this.parentJsonHash));
                } else {
                    this.setStorageValue("CardsCounter", "0");
                    this.setObject("0", this.jsonObjRetrieveMultiple);
                    this.setStorageValue(this.jsonObjRetrieveMultiple.shortCode, "0");
                    this.setStorageValue("thisShortCode", this.jsonObjRetrieveMultiple.shortCode);
                    this.setStorageValue("thisCardCounter", "0");
                }
            });
        }
    }

    async updateObject(parentJsonHash: any, jsonObject: any) {
        let keyToUpdate;
        const JsonDataLenght = Object.keys(jsonObject).length;
        this.getObject(parentJsonHash).then(CardData => {
            for (keyToUpdate in jsonObject) {
                CardData[keyToUpdate] = jsonObject[keyToUpdate];
            }
            Preferences.set({
                key: parentJsonHash,
                value: JSON.stringify(CardData)
            });
        });
    }

    async clear() {
        await Preferences.clear();
    }
    async removeItem(itemtoremove) {
        await Preferences.remove({ key: itemtoremove });
      }

    async migrate(){
       var values= await Preferences.migrate();
       return values;
    }  
      
}
