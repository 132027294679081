import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {SimpleLoadingStrategy} from './SimpleLoadingStrategy';

const routes: Routes = [
  
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'startup',
    loadChildren: () => import('./middlescreen/middlescreen.module').then( m => m.MiddlescreenPageModule)
  },
  {
    path: 'messaginglist',
    loadChildren: () => import('./ionmessaging/messaginglist/messaginglist.module').then( m => m.MessaginglistPageModule)
  },
  {
    path: 'messaginginner',
    loadChildren: () => import('./ionmessaging/messaginginner/messaginginner.module').then( m => m.MessaginginnerPageModule)
  },
  {
    path: 'languageselection',
    loadChildren: () => import('./languageselection/languageselection.module').then( m => m.LanguageselectionPageModule)
  },{
    path: 'loyalty',
    loadChildren: () => import('./ionloyalty/tab35-loyalty/tab35-loyalty.module').then( m => m.Tab35LoyaltyPageModule)
  },
];
@NgModule({
  providers: [SimpleLoadingStrategy], 
  imports: [
    RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
