import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'; // Required for translation.
import { WebheaderComponent } from './Webheader.component';
import { HttpClient } from '@angular/common/http';
import {HttpLoaderFactory} from '../app.module';



@NgModule({
  imports: [ CommonModule, FormsModule, IonicModule, TranslateModule.forChild({ // <--- add this
    loader: { 
      provide: TranslateLoader, 
      useFactory: (HttpLoaderFactory),  
      deps: [HttpClient] 
    } // <--- until here 
  })],
  declarations: [WebheaderComponent],
  exports: [WebheaderComponent]
})
export class WebheaderModule { }
