export enum Constants {
    _APP_OUTDATED = 0,
    _APP_UPDATED = 1,
    _FORM_PART_1 = 1,
    _FORM_PART_2 = 2,
    _FORM_PART_3_DONE = 3,
    _ADD_CARD_BY_SCAN = 1,
    _ADD_CARD_BY_SHORTCODE = 2,
    _NO_EXPIRATION_DATE = "0",
    _NOT_AVAILABLE = "N/A",
    ACTIVE = 1,
    INACTIVE = 0,
    ALL=0,
    EXPRESS=1,
    NONEXPRESS=2,
    MIN_PASSWORD=8, //Minimum password length
    
    _DEFAULT_ORGID = "628001",
    _DEFAULT_ENDPOINTURI = "https://api-dot-smash-eutest.appspot.com/external/v2/",
    //"live: https://api-dot-prdsmashtmm.appspot.com/external/v2/", test: https://api-dot-smash-eutest.appspot.com/external/v2/
         
    _TMMSA_STORE = "0",
    _TMMSA_ORGID = "628001",
    _TMMSA_ENDPOINTURI = "https://api-dot-smash-eutest.appspot.com/external/v2/",
    //"live: https://api-dot-prdsmashtmm.appspot.com/external/v2/", test: https://api-dot-smash-eutest.appspot.com/external/v2/
         
    _TMMBA_STORE = "1",
     _TMMBA_ORGID = "608002",
    _TMMBA_ENDPOINTURI = "https://api-dot-smash-eutest.appspot.com/external/v2/",
    //"live: https://api-dot-prdsmashmea.appspot.com/external/v2/", test: https://api-dot-smash-eutest.appspot.com/external/v2/

    /* ---------------------------------------------------------------------------------------------------------------------------------------------------- */
    /*                                GENERAL                                                                                                               */
    /* ---------------------------------------------------------------------------------------------------------------------------------------------------- */
    _REFRESH_ICON = "/assets/custom-ion-icons/refresh.svg",

    /* ---------------------------------------------------------------------------------------------------------------------------------------------------- */
    /*                                IONMAP SUBMODULE                                                                                                      */
    /* ---------------------------------------------------------------------------------------------------------------------------------------------------- */
    _CURRENT_USER_ICON = "/assets/user-marker.png",
    _PIN_MARKER_ICON = "/assets/store-marker.png",
    _STORE_MARKER_ICON = "/assets/store-marker.png",
    _GOOGLEMAP_API_KEY = "AIzaSyA7VUvqn2f8a0TbC7rtYL2Hgk8WVDFgXVU",
    // "AIzaSyA7VUvqn2f8a0TbC7rtYL2Hgk8WVDFgXVU",
    // "AIzaSyA7SR_VUgy0r4C2ewI9dYL2e0NNGqkbpdU", 
    _IONMAP_HEADER_LEFT_ICON_COLOR = "dark",
    _IONMAP_HEADER_LEFT_BADGE_COLOR = "success",
    _IONMAP_STARTING_POINT_LAT = 23.388089,
    _IONMAP_STARTING_POINT_LNG = 45.292944,
    _IONMAP_STARTING_POINT_LAT_BA = 26.218202,
    _IONMAP_STARTING_POINT_LNG_BA = 50.533850,
    _IONMAP_ZOOM_LEVEL_SA = 5,
    _IONMAP_ZOOM_LEVEL_BA = 10,
    _FILTER_ICON = INACTIVE, 
    _FILTER_MAP_BY_CITY = ACTIVE, 
    _FILTER_MAP_BY_CATEOGRY = ACTIVE, 
    _FILTER_MAP_CATEGORIES_NO= 2,

    /* ---------------------------------------------------------------------------------------------------------------------------------------------------- */
    /*                                IONBALANCE SUBMODULE                                                                                                  */
    /* ---------------------------------------------------------------------------------------------------------------------------------------------------- */
    _IONBALANCE_MODAL_COVER = "/assets/card.png",

    /* ---------------------------------------------------------------------------------------------------------------------------------------------------- */
    /*                                IONREGISTER SUBMODULE                                                                                                 */
    /* ---------------------------------------------------------------------------------------------------------------------------------------------------- */
    _IONREGISTER_LOGIN_ENTER_MOBILE_NUMBER_COVER = "/assets/defaults/default-cover.png",
    _IONREGISTER_SPONSOR_LOGO = "/assets/sponsorlogo.svg",
    _IONREGISTER_LOGIN_ENTER_PASSWORD_COVER = "/assets/defaults/default-cover.png",
    COUNTRY_CODE="966",
    COUNTRY_IMAGE="assets/img/SA.png",
    IONREGISTER_SPONSOR_IMG=ACTIVE,
    IONREGISTER_SELECT_STORE=ACTIVE,

    
    /* ---------------------------------------------------------------------------------------------------------------------------------------------------- */
    /*                                IONLOYALTY SUBMODULE                                                                                                  */
    /* ---------------------------------------------------------------------------------------------------------------------------------------------------- */
    _IONLOYALTY_COVER = "/assets/loyaltyCard.svg",

    /* ---------------------------------------------------------------------------------------------------------------------------------------------------- */
    /*                                IONWALLET SUBMODULE                                                                                                   */
    /* ---------------------------------------------------------------------------------------------------------------------------------------------------- */
    IONWALLET_DONATE_BUTTON_ENABLE="false",
 
    /* ---------------------------------------------------------------------------------------------------------------------------------------------------- */
    /*                                IONPROMOS SUBMODULE                                                                                                   */
    /* ---------------------------------------------------------------------------------------------------------------------------------------------------- */
    IONPROMOS_SHOW_SOCIAL_PROMO_BUTTON="true",
    /* ---------------------------------------------------------------------------------------------------------------------------------------------------- */
    /*                                SHOPONLINE SUBMODULE                                                                                                   */
    /* ---------------------------------------------------------------------------------------------------------------------------------------------------- */
    SHOP_ONLINE_COVER = "/assets/backgroundshop.png",
    SHOP_ONLINE_EXTERNALURL = "https://api-dot-smash-eutest.appspot.com/tmm-deep-link.html",
    


    /* ---------------------------------------------------------------------------------------------------------------------------------------------------- */
    /*                                MORE SUBMODULE                                                                                                   */
    /* ---------------------------------------------------------------------------------------------------------------------------------------------------- */
   
    MORE_INFO_COVER = "/assets/defaults/default-cover.png",
    MORE_COVER = "/assets/defaults/default-cover.png",
    MORE_ABOUT_COVER = "/assets/defaults/default-cover.png",
    MORE_TERMS_COVER = "/assets/defaults/default-cover.png",
    MORE_PRIVACY_COVER = "/assets/defaults/default-cover.png",

    /* ---------------------------------------------------------------------------------------------------------------------------------------------------- */
    /*                                LANGUAGE                                                                                                              */
    /* ---------------------------------------------------------------------------------------------------------------------------------------------------- */
    LANGUAGESWITCHER= ACTIVE,
    
    /* ---------------------------------------------------------------------------------------------------------------------------------------------------- */
    /*                                APP RATING                                                                                                              */
    /* ---------------------------------------------------------------------------------------------------------------------------------------------------- */
    REMIND_LATER_NUMBER_OF_USES= 10,
    APPLICATION_NUMBER_OF_USES_TILL_RATE= 10,

    //test-smash
    //  RECAPTCHA_SIDE_KEY="6LfDZj4qAAAAANjrFKfH5P6y-U91MJgxlKGEpXZM"

    //prod-web-mea
    RECAPTCHA_SIDE_KEY="6Ld2b0UqAAAAAE-k41JSjKDgB6LUPgWVU9PFXmk5"


}
