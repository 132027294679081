import { Component, OnInit, Input } from '@angular/core';
import { Platform, LoadingController, AlertController } from '@ionic/angular';
import { NavController } from '@ionic/angular';
import { GeneralmethodsService } from '../_services/generalmethods.service';

@Component({
  selector: 'app-modalpage',
  templateUrl: './modalpage.component.html',
  styleUrls: ['./modalpage.component.scss'],
})
export class ModalpageComponent implements OnInit {
  @Input() pageHTML: string;
  INFOMODAL_DISMISS_TEXT;

  constructor(
    public generalmethods: GeneralmethodsService,
    public navCtrl: NavController,
    public platform: Platform,
    public loadingController: LoadingController,
    public alertController: AlertController,
  ) { }

  ionViewDidEnter() {
    this.INFOMODAL_DISMISS_TEXT = this.generalmethods.gettranslation('INFOMODAL_DISMISS_TEXT');
  }

  async ngOnInit() {
  }

}
